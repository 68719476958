import React, { useContext, useEffect, useState } from 'react';
import { Badge, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { AppBar } from '../../Layout/components/NavigationBar/NavigationBar.styles';
import { AuthContext } from '../../../contexts/Auth.context';
import store from '../../../store';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useCommunity } from '../../../hooks/useCommunity';
import { ICommunity } from '../../../models/communities.modal';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import Close from '@mui/icons-material/Close';
import { logoutService } from '../../../services/logOut.service';

interface INavbarProps {}
// eslint-disable-next-line no-empty-pattern
const Header = ({}: INavbarProps) => {
  const navigate = useNavigate();

  useContext(AuthContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  // const communities = communityState?.communities?.communities;

  // console.log(communities, "communities");
  const { memberCommunities } = useCommunity();
  const [myMemberCommunities, setMyMemberCommunities] = useState<ICommunity[]>(
    []
  );

  // console.log(myMemberCommunities, "myMemberCommunities");

  useEffect(() => {
    async function fetchData() {
      const responseData = await memberCommunities();
      //console.log(responseData);
      // console.log("data", responseData);
      setMyMemberCommunities(responseData);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = store.getState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const [open, setOpen] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('xs');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    const success = await logoutService();
    if (success) {
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      window.location.reload();
      // navigate('/');
    } else {
      console.error('Logout failed, unable to navigate to login.');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  return (
    <Box
      component='nav'
      sx={{
        display: {
          xs: 'none',
          sm: 'none',
          md: 'block',
          lg: 'block',
          xl: 'block',
          xxl: 'block',
        },
      }}
    >
      <AppBar sx={{ backgroundColor: '#ffffff' }}>
        <Stack p={1}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }} spacing={10}>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginLeft: '10px',
                  }}
                >
                  {}
                  <IconButton size='large'>
                    <Badge>
                      <PowerSettingsNewOutlinedIcon
                        onClick={handleClickOpen}
                        sx={{ color: '#565656', fontSize: '4vh', pt: 0.3 }}
                      />
                    </Badge>
                  </IconButton>
                </div>

                {/* <IconButton
                    disableRipple
                    size="small"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleClickOpen}
                    color="inherit"
                    sx={{ ml: 3 }}
                  >
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                    >
                      <Avatar
                        src={loggedInUserData?.user?.avatar}
                        sx={{ backgroundColor: "#3C5AA0" }}
                      >
                        {loggedInUserData?.user?.avatar
                          ? null
                          : loggedInUserData?.user?.firstName?.charAt(0) +
                            "" +
                            loggedInUserData?.user?.lastName?.charAt(0)}
                      </Avatar>
                    </StyledBadge>
                    <PowerSettingsNewOutlinedIcon sx={{color:"#575757"}} />
                  </IconButton> */}
              </Box>
            </Grid>
          </Grid>
        </Stack>

        <Dialog maxWidth={maxWidth} open={open} onClose={handleClose}>
          <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ backgroundColor: '#F0F9FF' }}
            >
              <Stack></Stack>
              <Stack></Stack>
              <Stack sx={{ backgroundColor: '#F0F9FF' }}>
                <Box
                  onClick={handleClose}
                  sx={{
                    backgroundColor: '#50A1CA',
                    padding: '4px',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '10px',
                    mt: '0.5px',
                    mr: '10px',
                    cursor: 'pointer',
                    '&:hover': {
                      // backgroundColor: "#50A1CA", // Background color on hover
                      cursor: 'pointer',
                      border: 'none',
                    },
                  }}
                >
                  <Close sx={{ fontSize: '15px' }} />
                </Box>
              </Stack>
            </Stack>
          </DialogActions>
          <DialogTitle>
            <Typography
              sx={{
                fontSize: '15px',
                font: 'Roboto',
                // color: "red",
              }}
            >
              Are you sure you want to log out?
            </Typography>
          </DialogTitle>
          <Divider />

          <Stack
            direction='row'
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <DialogActions>
              <Button
                size='small'
                onClick={handleLogout}
                sx={{
                  cursor: 'pointer',
                  pr: 1,
                  textTransform: 'capitalize',
                  color: 'White',
                  background:
                    'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                }}
              >
                Yes
              </Button>
              <Divider orientation='vertical' style={{ marginLeft: 25 }} />
            </DialogActions>
            <Button
              size='small'
              variant='outlined'
              sx={{
                cursor: 'pointer',
                textTransform: 'capitalize',
                color: '#50A1CA',
                border: '1px solid #50A1CA',
              }}
              onClick={handleClose}
            >
              No
            </Button>
          </Stack>
        </Dialog>
      </AppBar>
    </Box>
  );
};

export default Header;
