import axios from 'axios';
import { BASE_URL } from '../configurations/url.config';

export const logoutService = async (): Promise<boolean> => {
  try {
    const response = await axios.get(`${BASE_URL}/logout`, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false,
    });

    console.log('Logout Response:', response);

    if (response.status === 200) {
      return true;
    } else {
      console.error('Logout failed:', response.data);
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error('Axios error:', error.response.data);
    } else {
      console.error('Error during logout:', error);
    }
    return false;
  }
};
